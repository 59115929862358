<template>
  <div class="width-100p plr-10">
    <div class="sub-header-blocker pa-1">
      <span class="sub-header-text d-flex align-center">
        <img
          src="src/assets/images/left-arrow.svg"
          class="sub-header-back-button mr-2"
          alt="back-button"
          @click="goToStopDetails"
        >
        Add Images
      </span>
    </div>
    <div class="d-flex width-100p align-center mb-8 pt-12">
      <div class="mr-4">
        <input
          type="file"
          name="attachment"
          id="attachment"
          accept="image/*"
          capture="camera"
          @change="onFileChange"
          ref="fileInput"
          @click="$refs.fileInput.value=null"
        >
        <label
          for="attachment"
          id="attachment"
        >
          <a
            class="d-flex justify-center align-center add-an-image-btn"
          >
            <div class="save-icon" />
            <div class="add-an-image-label">
              Add an image
            </div>
          </a>
        </label>
      </div>
      <div>
        <div
          class="save-and-exit-btn"
        >
          <button
            @click.prevent="saveAttachment('save')"
            v-if="attachmentList.length > 0"
          >
            Save and exit
          </button>
        </div>
      </div>
    </div>

    <MobileAttachmentsCard
      v-for="(attachment, index) in $v.attachmentList.$each.$iter"
      :key="index"
      :attachment-detail="attachment"
      :attachment-index="index"
      :form-submitted="submitted"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import MobileAttachmentsCard from '../../../helpers/component/attachments/mobileAttachmentsCard.vue';
import generateId from '../../../helpers/utils/generate-unique-id';

export default {
  name: 'StopAttachmentMobile',
  components: { MobileAttachmentsCard },
  computed: {
    ...mapGetters({
      attachmentList: 'attachment/getAttachments',
      attachmentMessage: 'attachment/getAttachmentSuccessMessage',
    }),
  },
  data() {
    return {
      submitted: false,
      stopId: this.$store.state.stopDetail.stopDetail.doc_id,
      isTriggerSaveAndExit: false,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.resizeImage(file);
    },
    saveAttachment(type) {
      if (type === 'save') {
        this.isTriggerSaveAndExit = false;
      }
      this.$v.attachmentList.$touch();
      if (this.$v.attachmentList.$invalid) {
        return;
      }
      const attachmentsToSave = this.attachmentList.filter((att) => att.from_firestore !== true);
      this.$store.dispatch('attachment/addAttachmentsData', {
        attachmentList: attachmentsToSave,
        stopId: this.stopId,
      });
    },
    goToStopDetails() {
      this.$router.push('/mobile/stop-detail');
    },
    resizeImage(file) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // limit the image to 1280x853 maximum size
      const maxW = 1280;
      const maxH = 853;
      const img = new Image();
      const vm = this;
      img.onload = () => {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min((maxW / iw), (maxH / ih));
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        const imageUrl = canvas.toDataURL('image/jpeg', 9);
        const attachmentDetail = {
          id: '',
          image_url: imageUrl,
          description: '',
          attachment_type: 'Photo',
          stop_id: vm.stopId,
          isNew: true,
        };
        const attachmentId = generateId();
        attachmentDetail.id = attachmentId;
        vm.$store.commit('attachment/addAttachment', attachmentDetail);
        vm.isTriggerSaveAndExit = true;
      };
      img.src = URL.createObjectURL(file);
    },
  },
  validations: {
    attachmentList: {
      $each: {
        image_url: { required },
        attachment_type: { required },
        description: { minLength: minLength(0), maxLength: maxLength(20) },
        $trackBy: 'id',
      },
    },
  },
  watch: {
    attachmentMessage(newVal) {
      if (newVal) {
        this.$store.commit('attachment/saveAttachmentSuccessCheck', false);
        if (!this.isTriggerSaveAndExit) {
          this.goToStopDetails();
        }
      }
    },
  },
};
</script>
