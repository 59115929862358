<template>
  <div class="mobile-stop-card-group mobile-trip-card">
    <div
      :class="stopDetail.is_active
        ? 'mobile-stop-active'
        : 'mobile-stop-todo'"
      class="d-flex mobile-stop-status"
    >
      <label>Status:</label>
      <div
        class="mobile-status-circle"
      />
      <label>
        {{ stopStatus }}
      </label>
    </div>
    <v-col
      class="mobile-stop-detail-card"
      cols="12"
    >
      <div class="d-flex">
        <v-col
          cols="5"
          class="padding-left-0"
        >
          <div class="mobile-stop-list-title">
            Stop number
          </div>
          <p class="mobile-stop-list-content">
            {{ stopDetail.stop_number }}
          </p>
        </v-col>
        <v-col
          cols="3"
        >
          <div class="mobile-stop-list-title">
            Trip
          </div>
          <p class="mobile-stop-list-content">
            {{ tripCode }}
          </p>
        </v-col>
        <v-col
          cols="4"
        >
          <div class="mobile-stop-list-title mb-1">
            Type of stop
          </div>
          <div class="d-flex">
            <StopIcon
              v-for="(stop_type, index) in stopDetail.type"
              :key="index"
              :stop-type="stop_type"
            />
          </div>
        </v-col>
      </div>
      <div class="mobile-stop-list-title">
        Stop name
      </div>
      <p class="mobile-stop-list-content">
        {{ stopDetail.generator.name }}
      </p>
      <div class="mobile-stop-list-title">
        Address
      </div>
      <p class="mobile-stop-list-content">
        {{ stopDetail.generator.site_address }}
      </p>
      <div class="mobile-stop-list-title">
        Phone number
      </div>
      <p class="mobile-stop-list-content">
        {{ stopDetail.generator.phone_number | phoneNumberFormat }}
      </p>
      <button
        block
        dark
        class="mobile-view-trip-btn"
        @click.prevent="gotoStopDetail()"
      >
        {{ stopAction }}
      </button>
    </v-col>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import StopIcon from '../stop-icon/stopIcon.vue';

export default {
  name: 'MobileStopCard',
  components: {
    StopIcon,
  },
  computed: {
    ...mapGetters({ user: 'authentication/getUser' }),
    stopStatus() {
      if (this.stopDetail.is_active) {
        return 'Active';
      }
      return 'To do';
    },
    stopAction() {
      if (this.stopDetail.is_active) {
        return 'View stop';
      }
      return 'Begin stop';
    },
  },
  mounted() {
    this.$store.dispatch('fetchActiveStopDetail', String(this.user.active_stop_id));
  },
  methods: {
    gotoStopDetail() {
      this.stopDetail.tripCode = this.tripCode;
      this.$store.commit('setStopDetail', this.stopDetail);
      if (this.stopDetail.is_active) {
        this.$router.push('/mobile/stop-detail');
      } else if (this.user.active_stop_id
        && String(this.stopDetail.id) !== this.user.active_stop_id) {
        this.$router.push({ name: 'Mobile Active Stop Warning' });
      } else {
        this.$router.push({ name: 'Mobile Stop Instruction' });
      }
    },
  },
  props: {
    stopDetail: {
      type: Object,
      default() {
        return {
          stop_number: '',
          stop_type: [],
          generator: {
            name: '',
            phone_number: '',
            site_address: '',
          },
        };
      },
    },
    tripCode: {
      type: String,
      default: '',
    },
  },
};
</script>
