//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import StopIcon from '../stop-icon/stopIcon.vue';

export default {
  name: 'MobileStopCard',
  components: {
    StopIcon,
  },
  computed: {
    ...mapGetters({ user: 'authentication/getUser' }),
    stopStatus() {
      if (this.stopDetail.is_active) {
        return 'Active';
      }
      return 'To do';
    },
    stopAction() {
      if (this.stopDetail.is_active) {
        return 'View stop';
      }
      return 'Begin stop';
    },
  },
  mounted() {
    this.$store.dispatch('fetchActiveStopDetail', String(this.user.active_stop_id));
  },
  methods: {
    gotoStopDetail() {
      this.stopDetail.tripCode = this.tripCode;
      this.$store.commit('setStopDetail', this.stopDetail);
      if (this.stopDetail.is_active) {
        this.$router.push('/mobile/stop-detail');
      } else if (this.user.active_stop_id
        && String(this.stopDetail.id) !== this.user.active_stop_id) {
        this.$router.push({ name: 'Mobile Active Stop Warning' });
      } else {
        this.$router.push({ name: 'Mobile Stop Instruction' });
      }
    },
  },
  props: {
    stopDetail: {
      type: Object,
      default() {
        return {
          stop_number: '',
          stop_type: [],
          generator: {
            name: '',
            phone_number: '',
            site_address: '',
          },
        };
      },
    },
    tripCode: {
      type: String,
      default: '',
    },
  },
};
